<template>
  <footer class="footer-wrapper">
    <contacts-company v-if="true"/>
    <footer-component v-if="true"/>
  </footer>
</template>
<script>
import contactsCompany from "../blocks/contactsCompany.vue";
import footerComponent from "../blocks/footerComponent.vue";
export default {
  components: {
    contactsCompany,
    footerComponent
  }
};
</script>
<style lang="scss" scoped>
  .footer-wrapper {
    position: relative;
  }
</style>
