<template>
  <div class="contacts" id="contacts">
    <img src="@/assets/image/Stethoscope.png" alt="#" class="contacts__image" />
    <div class="popup-success-box" v-if="successSend">
      <div class="popup-success">
        <button class="popup-success__close" @click="successSend = false, bodyLock()">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="15.0509" y1="15.0502" x2="24.9504" y2="24.9497" stroke="#CBCDF4" stroke-width="2"/>
            <line x1="24.9512" y1="15.0504" x2="15.0518" y2="24.9499" stroke="#CBCDF4" stroke-width="2"/>
          </svg>
        </button>
        <div class="popup-success__content">
          <span class="popup-success__title">Заявка принята</span>
          <span class="popup-success__text">Благодарим за ваше обращение</span>
          <img src="@\assets\image\popup-success.png" alt="готово" class="popup-success__image">
          <div class="popup-success__banner">Ожидайте звонка с номера <br> <b>+7 (921) 0250 250</b></div>
          <span class="popup-success__text popup-success__text_mb">Наш специалист свяжется с вами для обсуждения деталей</span>
          <button class="popup-success__button" @click="successSend = false, bodyLock()">Вернуться к сайту</button>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="contacts__form-box">
        <span class="form__pretitle">Напишите нам</span>
        <span class="form__title">Ответим на ваши вопросы</span>
        <span class="form__text"
          >Мы вас проконсультируем или подберем необходимые услуги</span
        >
        <form ref="form" action="#" class="form" id="contacts-form">
          <input
            class="form__item form__name"
            type="text"
            placeholder="Ваше имя"
            name="name"
            required
            @input="validateInputName = false"
            :class="{form__item_false: validateInputName}"
          />
          <input
            class="form__item form__phone"
            type="tel"
            placeholder="+7 (900) 000 00 00 "
            name="phone"
            required
            @input="validateInputPhone = false"
            :class="{form__item_false: validateInputPhone}"
          />
          <textarea
            class="form__item form__massage"
            placeholder="Ваш вопрос"
            name="message"
          ></textarea>
          <span class="form__validate-description"
            ><b>*</b> - поля, обязательные для заполнения</span
          >
          <button class="form__button" @click.prevent="customSubmitForm">Отправить заявку</button>
          <span class="form__privacy"
            >Отправляя свои контакты, вы соглашаетесь с нашей
            <a href="#">Политикой конфиденциальности</a></span
          >
        </form>
      </div>
      <div class="contacts__content">
        <h2 class="content__title">Контакты</h2>
        <div class="content__item">
          <span class="content__item-title">Наш телефон</span>
          <a href="#" class="content__link">8 (800) 222-86-84</a>
          <span class="content__text"
            >Звонки по РФ бесплатно, круглосуточно</span
          >
        </div>
        <div class="content__item">
          <span class="content__item-title">Наш email</span>
          <a href="#" class="content__link">clinic@artgorka.ru</a>
        </div>
        <div class="content__item">
          <span class="content__item-title">Часы работы</span>
          <span class="content__time">09:00 – 19:00 по будням</span>
        </div>
        <div class="content__item">
          <span class="content__item-title">Пишите нам</span>
          <div class="content__icon-list">
            <a href="https://vk.me/artclinik" class="content__icon-link"
              ><img src="@/assets/image/vk.svg" alt="vk"
            /></a>
            <a href="https://t.me/Art_clinik" class="content__icon-link"
              ><img src="@/assets/image/teleg.svg" alt="telegram"
            /></a>
            <a href="https://wa.me/79506819832?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C+%D1%83+%D0%BC%D0%B5%D0%BD%D1%8F+%D0%B5%D1%81%D1%82%D1%8C+%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81" class="content__icon-link"
              ><img src="@/assets/image/whatsapp.svg" alt="whatsapp"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      reName: /^([A-Za-z\-\']{1,50})|([А-Яа-я\-\']{1,50})$/i,
      rePhone: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/,
      reMessage: /^[а-яА-ЯёЁa-zA-Z0-9]+$/i,
      successSend: false,
      successTimer: null,
      validateInputName: false,
      validateInputPhone: false,
    }
  },
  methods: {
    bodyLock() {
      if(this.successSend) {
        document.body.classList.add('scrollOff')
      } else {
        document.body.classList.remove('scrollOff')
      }
    },
    customSubmitForm(e) {
      this.validationFormClass = [];
      const form = this.$refs.form;
      const inputs = Array.from(form.elements);
      for (const input of inputs) {
        if (
          input.required &&
          input.name == "name" &&
          !this.reName.test(input.value)
        ) {
          this.validateInputName = true
          console.log("имя")
          continue;
        }
        if (
          input.required &&
          input.name == "phone" &&
          !this.rePhone.test(input.value)
        ) {
          this.validateInputPhone = true
          console.log("телефон")
          continue;
        }
        if (
          input.required &&
          input.name == "message" &&
          !this.reMessage.test(input.value)
        ) {
          this.validationFormClass.push("empty-" + input.name);
          console.log("сообщение")
          continue;

        }
      }
      if (!this.validateInputName && !this.validateInputPhone) {
        this.sendMail()
      }
    },
    async sendMail() {
      let formData = new FormData(this.$refs.form);
      const response = await axios({
        method: "post",
        url: "https://artclinik.ru/api/mailQuestion.php",
        withCredentials: false,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        data: formData,
      });
      console.log(response);
      if (response.data.result == "success") {
        this.successSend = true;
        this.bodyLock()
        this.successTimer = setTimeout(
          function () {
            this.openCallBackPopUp = false;
            this.successSend = false;
            this.bodyLock()
            clearTimeout(this.successTimer);
            this.successTimer = null;
          }.bind(this),
          5000
        );
      } else {
        alert(
          "Не удалось отправить заявку, обновите страницу и попробуйте снова."
        );
      }
      // // axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      // // api.mail.sendMail({formData}).catch((err) => {
      // //   console.log(err);
      // // });
    },
  },
}
</script>
<style lang="scss" scoped>
.contacts {
  background: #f3f4f9;
  border-top: 16px solid #ecc0c7;
  width: 100%;
  padding: 120px 20px;
  position: relative;
  z-index: 3;
  .container {
    margin: 0 auto;
    max-width: 916px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__image {
    display: block;
    position: absolute;
    z-index: 1;
    top: calc(50% + 23px);
    left: calc(50% + 398px);
  }
  &__form {
    &-box {
      max-width: 520px;
      width: 100%;
      flex: none;
      background: #696fe6;
      border-radius: 20px;
      padding: 40px;
    }
  }
  .form {
    display: block;
    margin-top: 40px;
    &__pretitle {
      display: block;
      font-family: "Gilroy";
      font-weight: 500;
      font-size: 16px;
      color: #cbcdf4;
    }
    &__title {
      display: block;
      font-family: "Gilroy";
      font-weight: 700;
      font-size: 24px;
      color: #ffffff;
      margin-top: 10px;
    }
    &__text {
      display: block;
      margin-top: 10px;
      font-family: "Gilroy";
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
    }
    &__item {
      padding: 15px 20px;
      width: 100%;
      height: 49px;
      border: none;
      outline: none;
      background: #ffffff;
      border-radius: 20px;
      display: block;
      font-family: "Gilroy";
      font-weight: 400;
      font-size: 16px;
      color: #424c5c;
      &:not(:first-child) {
        margin-top: 20px;
      }
      &::placeholder {
        color: #a1a6ae;
      }
      &_false {
        border: 1px solid red;
      }
    }
    &__massage {
      resize: none;
      width: 100%;
      height: 120px;
    }
    &__validate {
      &-description {
        margin-top: 20px;
        display: block;
        font-family: "Gilroy";
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
        b {
          color: #ff5555;
        }
      }
    }
    &__button {
      margin-top: 20px;
      display: block;
      max-width: 440px;
      width: 100%;
      padding: 20px 30px;
      text-align: center;
      background: #ecc0c7;
      border: none;
      border-radius: 20px;
      font-family: "Gilroy";
      font-weight: 700;
      font-size: 16px;
      color: #5356ae;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        @media (min-width: 540px) {
          background: #ff5555;
          color: #fcf8f8;
        }
      }
    }
    &__privacy {
      margin-top: 20px;
      font-family: "Gilroy";
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: #bfc2ff;
      display: inline-block;
      a {
        display: inline-block;
        text-decoration: underline;
        font-family: "Gilroy";
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #bfc2ff;
      }
    }
  }
  &__content {
    margin-left: auto;
  }
  .content {
    &__title {
      font-family: "Gilroy";
      font-weight: 800;
      font-size: 30px;
      text-transform: uppercase;
      color: #424c5c;
      display: block;
    }
    &__item {
      margin-top: 20px;
      &-title {
        font-family: "Gilroy";
        font-weight: 400;
        font-size: 16px;
        color: #424c5c;
        opacity: 0.6;
        display: block;
      }
    }
    &__link {
      margin-top: 12px;
      font-family: "Gilroy";
      font-weight: 700;
      font-size: 24px;
      color: #696fe6;
      display: block;
    }
    &__text {
      margin-top: 12px;
      font-family: "Gilroy";
      font-weight: 400;
      font-size: 16px;
      color: #424c5c;
      display: block;
    }
    &__time {
      margin-top: 12px;
      font-family: "Gilroy";
      font-weight: 700;
      font-size: 20px;
      color: #424c5c;
      display: block;
    }
    &__icon {
      &-list {
        display: flex;
        margin-top: 12px;
      }
      &-link {
        width: 40px;
        display: block;
        text-decoration: none;
        img {
          display: block;
          width: 40px;
        }
        &:not(:first-child) {
          margin-left: 40px;
        }
      }
    }
  }
}

.popup-success {
  background: #696FE6;
  border-radius: 20px;
  max-width: 670px;
  width: 100%;
  padding: 60px 20px;
  position: relative;
  &-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    border: none;
    background: none;
  }
  &__content {
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title {
    width: 100%;
    text-align: center;
    display: block;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
  }
  &__text {
    display: block;
    width: 100%;
    margin-top: 10px;
    font-family: 'Gilroy';
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    &_mb {
      margin-top: 20px;
    }
  }
  &__image {
    width: 240px;
    margin: 0 auto;
    margin-top: 10px;
  }
  &__banner {
    margin: 0 auto;
    margin-top: 10px;
    padding: 15px 30px;
    max-width: 300px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    text-align: center;
    font-family: 'Gilroy';
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #696FE6;
    b {
      font-weight: 700;
      font-size: 20px;
    }
  }
  &__button {
    margin-top: 30px;
    width: 100%;
    display: block;
    background: #ECC0C7;
    border: none;
    border-radius: 20px;
    padding: 20px 30px;
    text-align: center;
    font-family: 'Gilroy';
    font-weight: 700;
    font-size: 16px;
    color: #5356AE;
  }
}

@media screen and (max-width: 1180px) {
  .contacts {
    &__image {
      left: calc(50% + 279px);
    }
  }
}
@media screen and (max-width: 900px) {
  .contacts {
    padding: 51px 20px 200px 20px;
    &__image {
      object-fit: contain;
      top: calc(50% + 144px);
      left: calc(50% + 133px);
      height: 420px;
    }
    &__form {
      &-box {
        max-width: 360px;
      }
    }
  }
}
@media screen and (max-width: 740px) {
  .contacts {
    padding: 80px 20px;
    .container {
      display: flex;
      flex-direction: column;
    }
    &__image {
      display: none;
    }
    &__content {
      margin-left: 0;
      order: -1;
    }
    &__form {
      &-box {
        margin-top: 80px;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .popup-success {
    background: #696FE6;
    border-radius: 20px;
    max-width: 670px;
    width: 100%;
    padding: 60px 20px;
    position: relative;
    &-box {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
      backdrop-filter: blur(3px);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
    }
    &__close {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      border: none;
      background: none;
    }
    &__content {
      max-width: 360px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__title {
      width: 100%;
      text-align: center;
      display: block;
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #FFFFFF;
    }
    &__text {
      display: block;
      width: 100%;
      margin-top: 5px;
      font-family: 'Gilroy';
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: #FFFFFF;
      &_mb {
        margin-top: 20px;
      }
    }
    &__image {
      width: 180px;
      margin: 0 auto;
      margin-top: 10px;
    }
    &__banner {
      margin: 0 auto;
      margin-top: 10px;
      padding: 10px 30px;
      max-width: 300px;
      width: 100%;
      background: #FFFFFF;
      border-radius: 20px;
      text-align: center;
      font-family: 'Gilroy';
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #696FE6;
      b {
        font-weight: 700;
        font-size: 16px;
      }
    }
    &__button {
      margin-top: 30px;
      width: 100%;
      display: block;
      background: #ECC0C7;
      border: none;
      border-radius: 20px;
      padding: 20px 30px;
      text-align: center;
      font-family: 'Gilroy';
      font-weight: 700;
      font-size: 14px;
      color: #5356AE;
    }
  }
}
</style>
