<template>
  <div class="block" id="block11">
    <div class="bacground-color">
      <div class="wrapper">
        <div class="title flex">
          <div class="text">кто всё это делает</div>
          <div class="description">
            Наша рабочая группа по направлению медицинского маркетинга.<br>Входит в
            состав digital-студии <b>ART gorka</b> – web-разработчиков с более
            чем 10-летним стажем.
          </div>
          <div class="decor-img"></div>
        </div>
        <div class="studio-employees">
          <div class="employee">
            <div class="employee__description flex-column">
              <div class="icon">
                <svg
                  width="28"
                  height="25"
                  viewBox="0 0 28 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.36583 8.08154L4.97333 21.7503H23.0283L24.6358 8.08154L19.6233 11.4228L14.0008 3.55154L8.37833 11.4228L3.36583 8.08154ZM2.50208 4.50029L7.75083 8.00029L12.9833 0.675286C13.099 0.513248 13.2516 0.381168 13.4286 0.290039C13.6056 0.19891 13.8018 0.151367 14.0008 0.151367C14.1999 0.151367 14.3961 0.19891 14.5731 0.290039C14.75 0.381168 14.9027 0.513248 15.0183 0.675286L20.2508 8.00029L25.5008 4.50029C25.6995 4.36809 25.9319 4.29554 26.1705 4.29121C26.4091 4.28687 26.644 4.35093 26.8474 4.47581C27.0507 4.6007 27.2141 4.78119 27.3181 4.99595C27.4222 5.21072 27.4626 5.45079 27.4346 5.68779L25.3808 23.1465C25.345 23.4506 25.1988 23.7309 24.97 23.9344C24.7413 24.1378 24.4457 24.2502 24.1396 24.2503H3.86208C3.55592 24.2502 3.26041 24.1378 3.03162 23.9344C2.80282 23.7309 2.65665 23.4506 2.62083 23.1465L0.567083 5.68654C0.539333 5.44964 0.57994 5.20975 0.684109 4.99518C0.788279 4.78062 0.951662 4.60033 1.15497 4.47561C1.35828 4.35089 1.59303 4.28694 1.83151 4.29132C2.06998 4.29569 2.30223 4.3682 2.50083 4.50029H2.50208ZM14.0008 16.7503C13.3378 16.7503 12.7019 16.4869 12.2331 16.0181C11.7642 15.5492 11.5008 14.9133 11.5008 14.2503C11.5008 13.5872 11.7642 12.9514 12.2331 12.4825C12.7019 12.0137 13.3378 11.7503 14.0008 11.7503C14.6639 11.7503 15.2998 12.0137 15.7686 12.4825C16.2374 12.9514 16.5008 13.5872 16.5008 14.2503C16.5008 14.9133 16.2374 15.5492 15.7686 16.0181C15.2998 16.4869 14.6639 16.7503 14.0008 16.7503Z"
                    fill="#696FE6"
                  />
                </svg>
              </div>
              <div class="content">
                <div class="name">Константин Кропотин</div>
                <div class="post">Руководитель и арт-директор студии</div>
              </div>
            </div>
            <div class="employee__avatar">
              <img src="@/assets/image/block11/Костя.webp" alt="Константин" />
            </div>
          </div>
          <div class="employee">
            <div class="employee__description flex-column">
              <div class="icon">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.81875 18.75L1.25 23.125V3.75C1.25 3.41848 1.3817 3.10054 1.61612 2.86612C1.85054 2.6317 2.16848 2.5 2.5 2.5H21.25C21.5815 2.5 21.8995 2.6317 22.1339 2.86612C22.3683 3.10054 22.5 3.41848 22.5 3.75V18.75H6.81875ZM5.95375 16.25H20V5H3.75V17.9813L5.95375 16.25ZM10 21.25H22.7962L25 22.9813V10H26.25C26.5815 10 26.8995 10.1317 27.1339 10.3661C27.3683 10.6005 27.5 10.9185 27.5 11.25V28.125L21.9313 23.75H11.25C10.9185 23.75 10.6005 23.6183 10.3661 23.3839C10.1317 23.1495 10 22.8315 10 22.5V21.25Z"
                    fill="#696FE6"
                  />
                </svg>
              </div>
              <div class="content">
                <div class="name">Александр Гончаров</div>
                <div class="post">Ответственный<br />за ваш проект</div>
              </div>
            </div>
            <div class="employee__avatar">
              <img src="@/assets/image/block11/СашаПМ.webp" alt="Александр" />
            </div>
          </div>
          <div class="employee">
            <div class="employee__description flex-column">
              <div class="icon">
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7459 0.299644L25.6796 8.23215C25.7614 8.31376 25.8189 8.41644 25.8458 8.52877C25.8727 8.64111 25.868 8.75871 25.8321 8.86852C25.7963 8.97833 25.7307 9.07605 25.6426 9.15083C25.5546 9.22561 25.4475 9.27453 25.3334 9.29215L23.4909 9.57965L16.4209 2.50964L16.6859 0.653394C16.7023 0.538608 16.7504 0.430668 16.8247 0.341648C16.8991 0.252629 16.9967 0.18604 17.1067 0.14933C17.2167 0.112621 17.3347 0.107238 17.4476 0.133784C17.5605 0.16033 17.6637 0.217758 17.7459 0.299644V0.299644ZM2.74461 24.1871C7.39711 20.0234 12.7384 18.7771 18.5484 17.2871L19.1059 12.2646L13.7346 6.8934L8.71211 7.4509C7.22211 13.2609 5.97586 18.6009 1.81211 23.2546L0.0683594 21.5121C3.60336 17.3871 4.93086 12.8209 6.69711 5.16089L14.6521 4.27715L21.7234 11.3484L20.8396 19.3034C13.1796 21.0709 8.61211 22.3971 4.48836 25.9321L2.74461 24.1871ZM9.34961 16.6496C9.11083 16.419 8.92038 16.1432 8.78936 15.8382C8.65833 15.5331 8.58937 15.2051 8.58648 14.8731C8.5836 14.5412 8.64685 14.212 8.77255 13.9048C8.89826 13.5975 9.08389 13.3184 9.31862 13.0837C9.55335 12.8489 9.83248 12.6633 10.1397 12.5376C10.447 12.4119 10.7762 12.3486 11.1081 12.3515C11.4401 12.3544 11.7681 12.4234 12.0731 12.5544C12.3781 12.6854 12.654 12.8759 12.8846 13.1146C13.3337 13.5872 13.5805 14.2166 13.5723 14.8685C13.564 15.5204 13.3015 16.1433 12.8405 16.6044C12.3796 17.0654 11.7568 17.3282 11.1049 17.3367C10.453 17.3451 9.82359 17.0986 9.35086 16.6496H9.34961Z"
                    fill="#696FE6"
                  />
                </svg>
              </div>
              <div class="content">
                <div class="name">Николай</div>
                <div class="post">Веб-дизайнер</div>
              </div>
            </div>
            <div class="employee__avatar">
              <img src="@/assets/image/block11/Николай.webp" alt="Николай" />
            </div>
          </div>
          <div class="employee">
            <div class="employee__description flex-column">
              <div class="icon">
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 25.5C6.09625 25.5 0.5 19.9037 0.5 13C0.5 7.4025 4.17875 2.665 9.25 1.0725V3.7275C7.10116 4.60006 5.32225 6.19213 4.21757 8.23139C3.1129 10.2707 2.7511 12.6304 3.19407 14.9069C3.63703 17.1835 4.85724 19.2354 6.64596 20.7117C8.43467 22.1879 10.6808 22.9968 13 23C14.9922 22.9999 16.9391 22.4051 18.5911 21.2915C20.243 20.178 21.5249 18.5967 22.2725 16.75H24.9275C23.335 21.8213 18.5975 25.5 13 25.5V25.5ZM25.4375 14.25H11.75V0.5625C12.1612 0.52125 12.5787 0.5 13 0.5C19.9037 0.5 25.5 6.09625 25.5 13C25.5 13.4213 25.4788 13.8388 25.4375 14.25ZM14.25 3.0775V11.75H22.9225C22.6443 9.54701 21.6411 7.49917 20.071 5.92904C18.5008 4.35892 16.453 3.35573 14.25 3.0775Z"
                    fill="#696FE6"
                  />
                </svg>
              </div>
              <div class="content">
                <div class="name">Анна</div>
                <div class="post">Интернет-маркетолог</div>
              </div>
            </div>
            <div class="employee__avatar">
              <img src="@/assets/image/block11/Анна.webp" alt="Анна" />
            </div>
          </div>
          <div class="employee">
            <div class="employee__description flex-column">
              <div class="icon">
                <svg
                  width="28"
                  height="27"
                  viewBox="0 0 28 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.25 10H25.25C25.913 10 26.5489 10.2634 27.0178 10.7322C27.4866 11.2011 27.75 11.837 27.75 12.5V15.13C27.7503 15.4567 27.6866 15.7803 27.5625 16.0825L23.6937 25.4763C23.5994 25.7053 23.4391 25.9011 23.2332 26.0389C23.0274 26.1766 22.7852 26.2501 22.5375 26.25H1.5C1.16848 26.25 0.850537 26.1183 0.616116 25.8839C0.381696 25.6495 0.25 25.3315 0.25 25V12.5C0.25 12.1685 0.381696 11.8505 0.616116 11.6161C0.850537 11.3817 1.16848 11.25 1.5 11.25H5.8525C6.05262 11.2501 6.24983 11.2021 6.42754 11.11C6.60526 11.018 6.75828 10.8847 6.87375 10.7213L13.69 1.0625C13.7762 0.940393 13.9032 0.853202 14.0482 0.81674C14.1931 0.780277 14.3463 0.796956 14.48 0.863752L16.7475 1.9975C17.3856 2.31645 17.8954 2.84414 18.1922 3.4929C18.4889 4.14165 18.5548 4.8724 18.3788 5.56375L17.25 10ZM7.75 13.235V23.75H21.7L25.25 15.13V12.5H17.25C16.8692 12.4999 16.4935 12.4129 16.1515 12.2456C15.8095 12.0782 15.5103 11.835 15.2766 11.5344C15.0429 11.2338 14.881 10.8837 14.8031 10.511C14.7253 10.1383 14.7336 9.75275 14.8275 9.38375L15.9562 4.94875C15.9916 4.81041 15.9785 4.66414 15.9191 4.53429C15.8598 4.40443 15.7577 4.29881 15.63 4.235L14.8037 3.8225L8.91625 12.1625C8.60375 12.605 8.20375 12.9675 7.75 13.235V13.235ZM5.25 13.75H2.75V23.75H5.25V13.75Z"
                    fill="#696FE6"
                  />
                </svg>
              </div>
              <div class="content">
                <div class="name">Артём</div>
                <div class="post">Специалист по соцсетям и таргетингу</div>
              </div>
            </div>
            <div class="employee__avatar">
              <img src="@/assets/image/block11/Артем.webp" alt="Артем" />
            </div>
          </div>
          <div class="employee">
            <div class="employee__description flex-column">
              <div class="icon">
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 0.5C22.69 0.5 23.25 1.06 23.25 1.75V6.44625L20.75 8.94625V3H3.25V23H20.75V19.5525L23.25 17.0525V24.25C23.25 24.94 22.69 25.5 22 25.5H2C1.31 25.5 0.75 24.94 0.75 24.25V1.75C0.75 1.06 1.31 0.5 2 0.5H22ZM24.2225 9.01L25.99 10.7775L16.2675 20.5L14.4975 20.4975L14.5 18.7325L24.2225 9.01V9.01ZM13.25 13V15.5H7V13H13.25ZM17 8V10.5H7V8H17Z"
                    fill="#696FE6"
                  />
                </svg>
              </div>
              <div class="content">
                <div class="name">Мария</div>
                <div class="post">Копирайтер</div>
              </div>
            </div>
            <div class="employee__avatar">
              <img src="@/assets/image/block11/Mariya.png" alt="Мария" />
            </div>
          </div>
          <div class="employee">
            <div class="employee__description flex-column">
              <div class="icon">
                <svg
                  width="26"
                  height="24"
                  viewBox="0 0 26 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.5 13.25H8V23.25H0.5V13.25ZM18 7H25.5V23.25H18V7ZM9.25 0.75H16.75V23.25H9.25V0.75ZM3 15.75V20.75H5.5V15.75H3ZM11.75 3.25V20.75H14.25V3.25H11.75ZM20.5 9.5V20.75H23V9.5H20.5Z"
                    fill="#696FE6"
                  />
                </svg>
              </div>
              <div class="content">
                <div class="name">Иван</div>
                <div class="post">
                  Специалист по контекстной рекламе и аналитике
                </div>
              </div>
            </div>
            <div class="employee__avatar">
              <img src="@/assets/image/block11/Иван.webp" alt="Иван" />
            </div>
          </div>
          <div class="employee">
            <div class="employee__description flex-column">
              <div class="icon">
                <svg
                  width="30"
                  height="24"
                  viewBox="0 0 30 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M30 12L22.9288 19.0712L21.1613 17.3038L26.465 12L21.1613 6.69625L22.9288 4.92875L30 12ZM3.535 12L8.83875 17.3038L7.07125 19.0712L0 12L7.07125 4.92875L8.8375 6.69625L3.535 12ZM12.235 23.25H9.575L17.765 0.75H20.425L12.235 23.25Z"
                    fill="#696FE6"
                  />
                </svg>
              </div>
              <div class="content">
                <div class="name">Александр</div>
                <div class="post">Программист</div>
              </div>
            </div>
            <div class="employee__avatar">
              <img src="@/assets/image/block11/Alexander.webp" alt="Александр" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.block {
  &#block11 {
    .bacground-color {
      background-color: #f3f4f9;
      padding: 120px 0px;
    }
    .title {
      margin-bottom: 40px;
      gap: 85px;
      position: relative;
      .text {
        width: 315px;
        //Стили текста
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 38px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #424c5c;
      }
      .description {
        width: 760px;
        //Стили текста
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #424c5c;
      }
      .decor-img {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background: #696fe6;
        left: 370px;
        top: -25px;
      }
    }
    .studio-employees {
      display: grid;
      gap: 40px;
      grid-template-columns: repeat(4, 260px);
      grid-template-rows: repeat(2, 323px);
      .employee {
        padding: 80px 20px 0px 0px;
        position: relative;
        .employee__description {
          width: 240px;
          height: 243px;
          background: #cbcdf4;
          border-radius: 20px;
          padding: 30px;
          .icon {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .content {
            margin-top: auto;
            .name {
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              line-height: 25px;
              letter-spacing: 0.02em;
              color: #5356ae;
              margin-bottom: 10px;
            }
            .post {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              letter-spacing: 0.02em;
              color: #424c5c;
            }
          }
        }
        .employee__avatar {
          top: 0;
          right: 0;
          position: absolute;
          width: 160px;
          height: 160px;
          border-radius: 100%;
          border: 3px solid #cbcdf4;
          background: #f3f4f9;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1365px) {
    &#block11 {
      .wrapper {
        width: 768px;
        padding: 0px 50px;
        .title {
          flex-direction: column;
          gap: 40px;
          .description {
            width: 100%;
            max-width: 533px;
          }
          .decor-img {
            left: -24px;
            top: 48px;
          }
        }
        .studio-employees {
          width: fit-content;
          margin: 0px auto;
          grid-template-columns: repeat(2, 260px);
          grid-template-rows: repeat(4, 323px);
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    &#block11 {
      .bacground-color {
        padding: 60px 0px;
        .wrapper {
          width: 100%;
          padding: 0px calc(10px + 40 * (100vw - 320px) / (767 - 320));
          .title {
            max-width: 660px;
            width: 100%;
            gap: 20px;
            .text {
              width: 100%;
              font-size: calc(24px + 6 * (100vw - 320px) / (767 - 320));
              line-height: calc(32px + 6 * (100vw - 320px) / (767 - 320));
            }
            .description {
              font-size: calc(14px + 2 * (100vw - 320px) / (767 - 320));
            }
            .decor-img {
              display: block;
              position: absolute;
              left: auto;
              top: -28px;
            }
          }
          .studio-employees {
            grid-template-columns: repeat(1, 260px);
            grid-template-rows: repeat(8, 323px);
            .employee {
              .employee__description {
                .content {
                  .name {
                    font-size: calc(16px + 4 * (100vw - 320px) / (767 - 320));
                    line-height: calc(21px + 4 * (100vw - 320px) / (767 - 320));
                  }
                  .post {
                    font-size: calc(14px + 2 * (100vw - 320px) / (767 - 320));
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
