<template>
    <div class="footer">
        <a href="#" class="footer__additional-logo">
            <img src="@/assets/image/footer-logo-clinic.svg" alt="artclinic">
        </a>
        <div class="container">
            <a class="footer__logo">
                <img src="@/assets/image/footer-logo-letter.svg" alt="artgorka">
            </a>
            <div class="footer__content">
                <span class="footer__item footer__description">студия дизайна, веб-разработок и маркетинга</span>
                <span class="footer__item footer__copyright">© 2022, Art gorka </span>
                <a href="#" class="footer__item footer__link">Политика обработки персональных данных</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
    .footer {
        width: 100%;
        background: #424C5C;
        padding: 140px 0px 60px;
        position: relative;
        overflow: hidden;
        .container {
             max-width: 1200px;
             width: 100%;
             padding: 0px 20px;
             margin: 0 auto;
        }
        &__logo {
            display: flex;
            flex: none;
            align-items: center;
            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background: #767D87;
                border-radius: 2px;
                margin-left: 32px;
            }
            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background: #767D87;
                border-radius: 2px;
                margin-right: 32px;
            }
        }
        &__additional {
            &-logo {
                display: block;
                position: absolute;
                top: -80px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        &__content {
            margin-top: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__item {
            display: block;
            font-family: 'Gilroy';
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
        }
        &__link {
            text-decoration: underline;
            max-width: 330px;
        }
    }
    @media screen and (max-width: 900px) {
        .footer {
            &__logo {
                &::before {
                    display: none;
                }
            }
            &__link {
                max-width: 250px;
                text-align: right;
            }
            &__description {
                max-width: 250px;
                width: 100%;
            }
            &__copyright {
                flex: none;
                margin: 0px 10px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .footer {
            &__content {
                align-items: stretch;
                justify-content: stretch;
                flex-direction: column;
            }
            &__item {
                &:not(:first-child) {
                    margin-top: 30px;
                }
            }
            &__link {
                text-align: left;
            }
            &__copyright {
                flex: auto;
                margin: 0px;
            }
        }
    }
</style>
