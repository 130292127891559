<template>
  <div class="block" id="block7">
    <div class="wrapper">
      <div class="decor-img" id="decor-img_1"></div>
      <div class="title flex">
        Кейсы от нашей студии
        <div class="title__decor">
          <div class="text">
            Приведём несколько примеров проектов, выполненных нашей студией.
            <br />В первую очередь при разработке мы отталкивались от
            поставленных задач, объемов индивидуальной работы в каждом проекте и
            от бюджета, выделенного на проект.
          </div>
        </div>
      </div>
      <div class="case" id="case_1">
        <div class="case__header flex">
          <div class="img-wrapper">
            <img src="@/assets/image/block7/case_1.png" alt="Кейс" />
            <div class="case-number">01</div>
          </div>
          <div class="case-title">
            <div class="name">Стоматология «ДоДоктор»,<br /></div>
            <div class="description">
              +2,2 млн. ₽ выручки за 5 месяцев с момента ребрендинга и запуска
              нового сайта с рекламой
            </div>
          </div>
        </div>
        <div class="casse__content flex-column">
          <div class="stage flex" id="stage_one">
            <div class="left flex-column">
              <div class="stage-name">
                <div class="stage-name__wrapper">Отправная точка</div>
                <div class="circle-decor"></div>
              </div>
            </div>
            <div class="right">
              <div class="text">
                Частной стоматологической клинике необходим дополнительный поток
                заявок на запись к специалистам. Локация клиники – одна клиника
                в городе с населением 230 тыс. человек.<br /><br />

                Под руководством опытного практикующего руководителя, «ДоДоктор»
                внедряет передовые цифровые технологии в работе с пациентами –
                это качественно отличает клинику от местных стоматологий –
                конкурентов.<br />
                Оффлайн канал привлечения новых пациентов работает стабильно,
                многие пациенты приходят по рекомендации друг друга.<br /><br />

                На момент знакомства с нашей студией в 2019 году на сайте ничего
                не менялось. Использовать для продвижения текущий сайт было
                невозможно, учитывая невнятный стиль, архаичность и явное
                несоответствие статусу и амбициям клиники.
              </div>
            </div>
          </div>
          <div class="stage flex" id="stage_two">
            <div class="left flex-column">
              <svg
                width="16"
                height="76"
                viewBox="0 0 16 76"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="decor-arrow"
              >
                <path
                  d="M7.64644 75.3536C7.84171 75.5488 8.15829 75.5488 8.35355 75.3536L11.5355 72.1716C11.7308 71.9763 11.7308 71.6597 11.5355 71.4645C11.3403 71.2692 11.0237 71.2692 10.8284 71.4645L8 74.2929L5.17157 71.4645C4.97631 71.2692 4.65973 71.2692 4.46446 71.4645C4.2692 71.6597 4.2692 71.9763 4.46446 72.1716L7.64644 75.3536ZM7.5 -2.18557e-08L7.5 2.34375L8.5 2.34375L8.5 2.18557e-08L7.5 -2.18557e-08ZM7.5 7.03125L7.5 11.7187L8.5 11.7188L8.5 7.03125L7.5 7.03125ZM7.5 16.4062L7.5 21.0937L8.5 21.0938L8.5 16.4063L7.5 16.4062ZM7.5 25.7812L7.5 30.4687L8.5 30.4688L8.5 25.7813L7.5 25.7812ZM7.5 35.1562L7.5 39.8437L8.5 39.8438L8.5 35.1563L7.5 35.1562ZM7.5 44.5312L7.5 49.2187L8.5 49.2188L8.5 44.5313L7.5 44.5312ZM7.5 53.9062L7.5 58.5937L8.5 58.5938L8.5 53.9063L7.5 53.9062ZM7.5 63.2812L7.5 67.9687L8.5 67.9688L8.5 63.2813L7.5 63.2812ZM7.5 72.6562L7.5 75L8.5 75L8.5 72.6563L7.5 72.6562ZM7.29289 75.7071C7.68341 76.0976 8.31658 76.0976 8.7071 75.7071L15.0711 69.3431C15.4616 68.9526 15.4616 68.3195 15.0711 67.9289C14.6805 67.5384 14.0474 67.5384 13.6569 67.9289L8 73.5858L2.34314 67.9289C1.95262 67.5384 1.31945 67.5384 0.928929 67.9289C0.538405 68.3195 0.538405 68.9526 0.928929 69.3431L7.29289 75.7071ZM7 -4.37114e-08L7 2.34375L9 2.34375L9 4.37114e-08L7 -4.37114e-08ZM7 7.03125L7 11.7187L9 11.7188L9 7.03125L7 7.03125ZM7 16.4062L7 21.0937L9 21.0938L9 16.4063L7 16.4062ZM7 25.7812L7 30.4687L9 30.4688L9 25.7813L7 25.7812ZM7 35.1562L7 39.8437L9 39.8438L9 35.1563L7 35.1562ZM7 44.5312L7 49.2187L9 49.2188L9 44.5313L7 44.5312ZM7 53.9062L7 58.5937L9 58.5938L9 53.9063L7 53.9062ZM7 63.2812L7 67.9687L9 67.9688L9 63.2813L7 63.2812ZM7 72.6562L7 75L9 75L9 72.6563L7 72.6562Z"
                  fill="#696FE6"
                />
              </svg>
              <div class="stage-name">
                <div class="stage-name__wrapper">Задача</div>
                <div class="circle-decor"></div>
              </div>
            </div>
            <div class="right">
              <ul class="list flex-column">
                <li>
                  Полностью обновить и внедрить бренд клиники, акцентируя на
                  профессионализм и высокий уровень применяемых цифровых
                  технологий, не имеющих аналогов в ближайших районах.
                </li>
                <li>
                  Разработать полностью новый сайт, не применяя шаблонных и
                  привычных решений в нише.
                </li>
                <li>Оптимизировать новый сайт по SEO.</li>
                <li>
                  Настроить эффективную контекстную рекламу под наиболее
                  перспективные направления и процедуры лечения в клинике.
                </li>
                <li>
                  Сформировать стабильный дополнительный поток новых пациентов,
                  увеличить средний чек минимум в 1,5 раза.
                </li>
              </ul>
            </div>
          </div>
          <div class="stage flex" id="stage_three">
            <div class="left flex-column">
              <svg
                width="16"
                height="76"
                viewBox="0 0 16 76"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="decor-arrow"
              >
                <path
                  d="M7.64644 75.3536C7.84171 75.5488 8.15829 75.5488 8.35355 75.3536L11.5355 72.1716C11.7308 71.9763 11.7308 71.6597 11.5355 71.4645C11.3403 71.2692 11.0237 71.2692 10.8284 71.4645L8 74.2929L5.17157 71.4645C4.97631 71.2692 4.65973 71.2692 4.46446 71.4645C4.2692 71.6597 4.2692 71.9763 4.46446 72.1716L7.64644 75.3536ZM7.5 -2.18557e-08L7.5 2.34375L8.5 2.34375L8.5 2.18557e-08L7.5 -2.18557e-08ZM7.5 7.03125L7.5 11.7187L8.5 11.7188L8.5 7.03125L7.5 7.03125ZM7.5 16.4062L7.5 21.0937L8.5 21.0938L8.5 16.4063L7.5 16.4062ZM7.5 25.7812L7.5 30.4687L8.5 30.4688L8.5 25.7813L7.5 25.7812ZM7.5 35.1562L7.5 39.8437L8.5 39.8438L8.5 35.1563L7.5 35.1562ZM7.5 44.5312L7.5 49.2187L8.5 49.2188L8.5 44.5313L7.5 44.5312ZM7.5 53.9062L7.5 58.5937L8.5 58.5938L8.5 53.9063L7.5 53.9062ZM7.5 63.2812L7.5 67.9687L8.5 67.9688L8.5 63.2813L7.5 63.2812ZM7.5 72.6562L7.5 75L8.5 75L8.5 72.6563L7.5 72.6562ZM7.29289 75.7071C7.68341 76.0976 8.31658 76.0976 8.7071 75.7071L15.0711 69.3431C15.4616 68.9526 15.4616 68.3195 15.0711 67.9289C14.6805 67.5384 14.0474 67.5384 13.6569 67.9289L8 73.5858L2.34314 67.9289C1.95262 67.5384 1.31945 67.5384 0.928929 67.9289C0.538405 68.3195 0.538405 68.9526 0.928929 69.3431L7.29289 75.7071ZM7 -4.37114e-08L7 2.34375L9 2.34375L9 4.37114e-08L7 -4.37114e-08ZM7 7.03125L7 11.7187L9 11.7188L9 7.03125L7 7.03125ZM7 16.4062L7 21.0937L9 21.0938L9 16.4063L7 16.4062ZM7 25.7812L7 30.4687L9 30.4688L9 25.7813L7 25.7812ZM7 35.1562L7 39.8437L9 39.8438L9 35.1563L7 35.1562ZM7 44.5312L7 49.2187L9 49.2188L9 44.5313L7 44.5312ZM7 53.9062L7 58.5937L9 58.5938L9 53.9063L7 53.9062ZM7 63.2812L7 67.9687L9 67.9688L9 63.2813L7 63.2812ZM7 72.6562L7 75L9 75L9 72.6563L7 72.6562Z"
                  fill="#696FE6"
                />
              </svg>
              <div class="stage-name">
                <div class="stage-name__wrapper">Результат</div>
                <div class="circle-decor"></div>
              </div>
            </div>
            <div class="right">
              <div class="text">
                Сравнивая статистику января 2020 и октября 2019 видно, что в
                январе количество обращений выросло до 63 вместо 24 в октябре, а
                стоимость обращения упала с 1250 ₽ до 437 ₽. Итого за 2 месяца
                работы: +262% к количеству обращений и -65% к стоимости
                обращения.<br /><br />
                Помимо разработки сайта с выстраиванием системы по привлечению
                клиентов, мы приняли участие в масштабном ребрендинге
                стоматологии и помогли «упаковать» клинику абсолютно всем -
                начиная от памятки пациента и бейджами для сотрудников,
                заканчивая оформлением соцсетей и современными вывесками снаружи
                и внутри клиники.
              </div>
            </div>
          </div>
          <div class="case__buttons flex">
            <div
              class="pink-button"
              @click="
                {
                  this.$store.dispatch('updateCallBackPopUpType', 'case');
                  this.$store.dispatch('updateOpenCallBackPopUp', true);
                }
              "
            >
              Хочу так же!
            </div>
            <a
              class="blue-border-button"
              href="https://dodoktor.com/"
              target="_blank"
              >Смотреть сайт</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="bacground-wrapper">
      <div class="wrapper">
        <div class="case" id="case_2">
          <div class="case__header flex">
            <div class="img-wrapper">
              <img src="@/assets/image/block7/case_2.png" alt="Кейс" />
              <div class="case-number">02</div>
            </div>
            <div class="case-title">
              <div class="name">
                Стоматология «Смaйл»,<br />
                г. Великий Новгород
              </div>
              <div class="description">
                В 2,5 раза больше обращений в стоматологию<br />за 2 месяца
              </div>
            </div>
          </div>
          <div class="casse__content flex-column">
            <div class="stage flex" id="stage_one">
              <div class="left flex-column">
                <div class="stage-name">
                  <div class="stage-name__wrapper">Отправная точка</div>
                  <div class="circle-decor"></div>
                </div>
              </div>
              <div class="right">
                <div class="text">
                  За счет ведения группы ВК с таргетированной рекламой мы уже
                  второй год обеспечивали заявками клиента – частную
                  стоматологию, расположенную в спальном районе маленького
                  города. Социальная сеть каждый месяц приносила прогнозируемое
                  число заявок.<br /><br />

                  Но где взять дополнительный трафик? Наш клиент достаточно
                  консервативен и откладывал вопрос о создании сайта. Но сайт –
                  это как минимум официальная страница о деятельности
                  стоматологии. А потенциальные клиенты не всегда активные
                  пользователи ВКонтакте, при этом продолжают искать и
                  сравнивать стоматологии, услуги и стоимость в поисковиках
                  Яндекс и Google.
                </div>
              </div>
            </div>
            <div class="stage flex" id="stage_two">
              <div class="left flex-column">
                <svg
                  width="16"
                  height="76"
                  viewBox="0 0 16 76"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="decor-arrow"
                >
                  <path
                    d="M7.64644 75.3536C7.84171 75.5488 8.15829 75.5488 8.35355 75.3536L11.5355 72.1716C11.7308 71.9763 11.7308 71.6597 11.5355 71.4645C11.3403 71.2692 11.0237 71.2692 10.8284 71.4645L8 74.2929L5.17157 71.4645C4.97631 71.2692 4.65973 71.2692 4.46446 71.4645C4.2692 71.6597 4.2692 71.9763 4.46446 72.1716L7.64644 75.3536ZM7.5 -2.18557e-08L7.5 2.34375L8.5 2.34375L8.5 2.18557e-08L7.5 -2.18557e-08ZM7.5 7.03125L7.5 11.7187L8.5 11.7188L8.5 7.03125L7.5 7.03125ZM7.5 16.4062L7.5 21.0937L8.5 21.0938L8.5 16.4063L7.5 16.4062ZM7.5 25.7812L7.5 30.4687L8.5 30.4688L8.5 25.7813L7.5 25.7812ZM7.5 35.1562L7.5 39.8437L8.5 39.8438L8.5 35.1563L7.5 35.1562ZM7.5 44.5312L7.5 49.2187L8.5 49.2188L8.5 44.5313L7.5 44.5312ZM7.5 53.9062L7.5 58.5937L8.5 58.5938L8.5 53.9063L7.5 53.9062ZM7.5 63.2812L7.5 67.9687L8.5 67.9688L8.5 63.2813L7.5 63.2812ZM7.5 72.6562L7.5 75L8.5 75L8.5 72.6563L7.5 72.6562ZM7.29289 75.7071C7.68341 76.0976 8.31658 76.0976 8.7071 75.7071L15.0711 69.3431C15.4616 68.9526 15.4616 68.3195 15.0711 67.9289C14.6805 67.5384 14.0474 67.5384 13.6569 67.9289L8 73.5858L2.34314 67.9289C1.95262 67.5384 1.31945 67.5384 0.928929 67.9289C0.538405 68.3195 0.538405 68.9526 0.928929 69.3431L7.29289 75.7071ZM7 -4.37114e-08L7 2.34375L9 2.34375L9 4.37114e-08L7 -4.37114e-08ZM7 7.03125L7 11.7187L9 11.7188L9 7.03125L7 7.03125ZM7 16.4062L7 21.0937L9 21.0938L9 16.4063L7 16.4062ZM7 25.7812L7 30.4687L9 30.4688L9 25.7813L7 25.7812ZM7 35.1562L7 39.8437L9 39.8438L9 35.1563L7 35.1562ZM7 44.5312L7 49.2187L9 49.2188L9 44.5313L7 44.5312ZM7 53.9062L7 58.5937L9 58.5938L9 53.9063L7 53.9062ZM7 63.2812L7 67.9687L9 67.9688L9 63.2813L7 63.2812ZM7 72.6562L7 75L9 75L9 72.6563L7 72.6562Z"
                    fill="#696FE6"
                  />
                </svg>
                <div class="stage-name">
                  <div class="stage-name__wrapper">Задача</div>
                  <div class="circle-decor"></div>
                </div>
              </div>
              <div class="right">
                <ul class="list flex-column">
                  <li>
                    Создать конверсионный сайт и проработать его стилистику и
                    содержание: удобный, с понятным интерфейсом для клиентов.
                  </li>
                  <li>
                    Изучить прайс клиники и на его основе самостоятельно
                    подготовить семантику, подобрать фото и написать тексты для
                    сайта, привлекая к работе копирайтера студии.
                  </li>
                  <li>Выполнить базовую SEO оптимизацию.</li>
                  <li>
                    Сформировать дополнительный поток новых пациентов, увеличить
                    количество обращений в стоматологию минимум в 2 раза за счет
                    внедрения сайта и эффективной настройки и ведения
                    контекстной рекламы на услуги стоматологии.
                  </li>
                </ul>
              </div>
            </div>
            <div class="stage flex" id="stage_three">
              <div class="left flex-column">
                <svg
                  width="16"
                  height="76"
                  viewBox="0 0 16 76"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="decor-arrow"
                >
                  <path
                    d="M7.64644 75.3536C7.84171 75.5488 8.15829 75.5488 8.35355 75.3536L11.5355 72.1716C11.7308 71.9763 11.7308 71.6597 11.5355 71.4645C11.3403 71.2692 11.0237 71.2692 10.8284 71.4645L8 74.2929L5.17157 71.4645C4.97631 71.2692 4.65973 71.2692 4.46446 71.4645C4.2692 71.6597 4.2692 71.9763 4.46446 72.1716L7.64644 75.3536ZM7.5 -2.18557e-08L7.5 2.34375L8.5 2.34375L8.5 2.18557e-08L7.5 -2.18557e-08ZM7.5 7.03125L7.5 11.7187L8.5 11.7188L8.5 7.03125L7.5 7.03125ZM7.5 16.4062L7.5 21.0937L8.5 21.0938L8.5 16.4063L7.5 16.4062ZM7.5 25.7812L7.5 30.4687L8.5 30.4688L8.5 25.7813L7.5 25.7812ZM7.5 35.1562L7.5 39.8437L8.5 39.8438L8.5 35.1563L7.5 35.1562ZM7.5 44.5312L7.5 49.2187L8.5 49.2188L8.5 44.5313L7.5 44.5312ZM7.5 53.9062L7.5 58.5937L8.5 58.5938L8.5 53.9063L7.5 53.9062ZM7.5 63.2812L7.5 67.9687L8.5 67.9688L8.5 63.2813L7.5 63.2812ZM7.5 72.6562L7.5 75L8.5 75L8.5 72.6563L7.5 72.6562ZM7.29289 75.7071C7.68341 76.0976 8.31658 76.0976 8.7071 75.7071L15.0711 69.3431C15.4616 68.9526 15.4616 68.3195 15.0711 67.9289C14.6805 67.5384 14.0474 67.5384 13.6569 67.9289L8 73.5858L2.34314 67.9289C1.95262 67.5384 1.31945 67.5384 0.928929 67.9289C0.538405 68.3195 0.538405 68.9526 0.928929 69.3431L7.29289 75.7071ZM7 -4.37114e-08L7 2.34375L9 2.34375L9 4.37114e-08L7 -4.37114e-08ZM7 7.03125L7 11.7187L9 11.7188L9 7.03125L7 7.03125ZM7 16.4062L7 21.0937L9 21.0938L9 16.4063L7 16.4062ZM7 25.7812L7 30.4687L9 30.4688L9 25.7813L7 25.7812ZM7 35.1562L7 39.8437L9 39.8438L9 35.1563L7 35.1562ZM7 44.5312L7 49.2187L9 49.2188L9 44.5313L7 44.5312ZM7 53.9062L7 58.5937L9 58.5938L9 53.9063L7 53.9062ZM7 63.2812L7 67.9687L9 67.9688L9 63.2813L7 63.2812ZM7 72.6562L7 75L9 75L9 72.6563L7 72.6562Z"
                    fill="#696FE6"
                  />
                </svg>
                <div class="stage-name">
                  <div class="stage-name__wrapper">Результат</div>
                  <div class="circle-decor"></div>
                </div>
              </div>
              <div class="right">
                <div class="text">
                  Базовым инструментом для привлечения клиентов стал сайт
                  клиники с возможностью записи на приём или консультацию. Сайт
                  выполнен в легком и ненавязчивом стиле.<br /><br />

                  За 2 месяца рекламной кампании в поисковых сетях сайт
                  стоматологии посетили 1874 человек, более 93% из общего
                  количества посетителей в Интернете – по рекламе.<br /><br />

                  Контекстная реклама на сайт была запущена в поисковых системах
                  Яндекс и Гугл. За два месяца по рекламе записались на прием 32
                  человека.<br />
                  Для небольшой стоматологии с 4-мя врачами терапевтами это
                  дополнительный прирост, учитывая сложившийся трафик постоянных
                  клиентов. В ближайшее время планируем внедрение CRM и
                  настройку систем сквозной аналитики.<br /><br />

                  Стоимость одного лида в первый месяц продвижения составила 852
                  руб, а во второй месяц после оптимизации стоимость лида была
                  снижена на 35% и составила 553 руб. Работа над показателями
                  продолжается.
                </div>
              </div>
            </div>
            <div class="case__buttons flex">
              <div
                class="pink-button"
                @click="
                  {
                    this.$store.dispatch('updateCallBackPopUpType', 'case');
                    this.$store.dispatch('updateOpenCallBackPopUp', true);
                  }
                "
              >
                Хочу так же!
              </div>
              <a
                class="blue-border-button"
                href="https://smile53.ru/"
                target="_blank"
                >Смотреть сайт</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.block {
  &#block7 {
    padding: 120px 0px 0px;
    .decor-img {
      &#decor-img_1 {
        position: absolute;
        width: 20px;
        height: 20px;
        background: #5356ae;
        transform: rotate(35.78deg);
        left: 480px;
        top: -30px;
      }
    }
    .title {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;
      text-align: left;
      gap: 105px;
      margin-bottom: 50px;
      //Стили текста
      font-style: normal;
      font-weight: 800;
      font-size: 30px;
      line-height: 38px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #424c5c;
      .title__decor {
        .text {
          text-transform: none;
          width: 660px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0px;
          color: #424c5c;
        }
      }
    }
    .wrapper {
      position: relative;
    }
    .case {
      .case__header {
        gap: 40px;
        margin-bottom: 50px;
        .img-wrapper {
          position: relative;
          width: 660px;
          height: 230px;
          border-radius: 20px;
          overflow: hidden;
          .case-number {
            position: absolute;
            font-style: normal;
            font-weight: 700;
            font-size: 147.904px;
            line-height: 183px;
            letter-spacing: 0.02em;
            color: #ffffff;
            bottom: -37px;
            left: 30px;
          }
        }
        .case-title {
          max-width: 460px;
          .name {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 0.02em;
            color: #696fe6;
            margin-bottom: 20px;
          }
          .description {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.02em;
            color: #696fe6;
          }
        }
      }
      .casse__content {
        width: 980px;
        padding-left: 20px;
        margin: 0px auto;
        gap: 55px;
        .stage {
          gap: 40px;
          .left {
            width: 260px;
            align-items: center;
            gap: calc((100% - 85px) / 2);
            .stage-name {
              position: relative;

              .stage-name__wrapper {
                position: relative;
                z-index: 1;
                width: 260px;
                height: 85px;
                background: #ffffff;
                box-shadow: 0px 14px 30px rgba(105, 111, 229, 0.14);
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                //Стили текста
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 25px;
                text-align: center;
                letter-spacing: 0.02em;
              }
              .circle-decor {
                width: 54px;
                height: 54px;
                border-radius: 100%;
                background-color: #696fe5;
                position: absolute;
                top: -15px;
                left: -20px;
              }
            }
          }
          .right {
            flex: 1;
            * {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 150%;
              color: #424c5c;
            }
            ul.list {
              &.flex-column {
                gap: 20px;
              }
              padding-left: 20px;
              list-style-type: none;
              li {
                position: relative;
                &::before {
                  content: "";
                  width: 6px;
                  height: 6px;
                  border-radius: 100%;
                  background: #ecc0c7;
                  position: absolute;
                  left: -20px;
                  top: 7px;
                }
              }
            }
          }
          &:nth-of-type(1) {
            .left {
              position: relative;
              .stage-name {
                .stage-name__wrapper {
                  color: #cbcdf4;
                }
                .circle-decor {
                  background: #ecc0c7;
                }
              }
            }
          }
          &:nth-of-type(2) {
            .left {
              position: relative;
              .stage-name {
                .stage-name__wrapper {
                  color: #696fe6;
                }
                .circle-decor {
                  background: #cbcdf4;
                }
              }
              .decor-arrow {
                position: absolute;
                top: -238px;

                path {
                  fill: #cbcdf4;
                }
              }
            }
          }
          &:nth-of-type(3) {
            .left {
              position: relative;
              .stage-name {
                .stage-name__wrapper {
                  color: #5356ae;
                }
                .circle-decor {
                  background: #696fe6;
                }
              }
              .decor-arrow {
                position: absolute;
                top: -170px;

                path {
                  fill: #cbcdf4;
                }
              }
            }
          }
        }
        .case__buttons {
          justify-content: flex-end;
          gap: 40px;
          padding-right: 20px;
        }
      }
      &#case_1 {
        margin-bottom: 70px;
      }
    }
    .bacground-wrapper {
      width: calc(100% - 200px);
      min-width: 1366px;
      margin: 0px auto;
      padding: 70px 0px 70px;
      background: #f3f4f9;
      border-radius: 20px;
    }
  }
  @media screen and (max-width: 1365px) {
    &#block7 {
      .wrapper {
        width: 768px;
        padding: 0px 50px;
        .decor-img {
          &#decor-img_1 {
            left: 14px;
            top: 43px;
          }
        }
        .title {
          flex-direction: column;
          gap: 40px;
          .text {
            width: 100%;
          }
        }
        .case {
          .case__header {
            flex-direction: column;
          }
          .casse__content {
            gap: 40px;
            width: 100%;
            .stage {
              flex-direction: column;
              gap: 40px;
              .left {
                gap: 65px;
                .decor-arrow {
                  position: relative;
                  top: auto;
                }
              }
            }
          }
        }
      }
      .bacground-wrapper {
        padding: 60px 0px 100px;
        min-width: auto;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 767px) {
    &#block7 {
      .wrapper {
        width: 100%;
        padding: 0px calc(10px + 40 * (100vw - 320px) / (767 - 320));
        .decor-img {
          &#decor-img_1 {
            left: calc(42px + 40 * (100vw - 320px) / (767 - 320));
            top: -32px;
          }
        }
        .title {
          font-size: calc(24px + 6 * (100vw - 320px) / (767 - 320));
          line-height: calc(32px + 6 * (100vw - 320px) / (767 - 320));
          gap: 20px;
          margin-bottom: 40px;
          .title__decor {
            .text {
              font-size: calc(14px + 2 * (100vw - 320px) / (767 - 320));
              br {
                display: none;
              }
            }
          }
        }
        .case {
          .case__header {
            margin-bottom: 40px;
            .img-wrapper {
              width: 100%;
              max-width: 660px;
              img {
                position: absolute;
                top: 0;
                left: auto;
              }
              .case-number {
                font-size: calc(100px + 47 * (100vw - 320px) / (767 - 320));
                line-height: 100%;
                bottom: -20px;
              }
            }
            .case-title {
              .name {
                font-size: calc(20px + 4 * (100vw - 320px) / (767 - 320));
                line-height: calc(26px + 4 * (100vw - 320px) / (767 - 320));
              }
              .description {
                font-size: calc(14px + 2 * (100vw - 320px) / (767 - 320));
                line-height: calc(18px + 2 * (100vw - 320px) / (767 - 320));
              }
            }
          }
          .casse__content {
            gap: 30px;
            .stage {
              gap: 30px;
              align-items: center;
              .left {
                gap: 25px;
                .stage-name {
                  .stage-name__wrapper {
                    font-size: calc(16px + 4 * (100vw - 320px) / (767 - 320));
                    line-height: calc(21px + 4 * (100vw - 320px) / (767 - 320));
                  }
                }
              }
              .right {
                .text {
                  font-size: calc(14px + 2 * (100vw - 320px) / (767 - 320));
                  line-height: calc(18px + 2 * (100vw - 320px) / (767 - 320));
                }
              }
            }
            .case__buttons {
              flex-wrap: wrap;
              justify-content: center;
              gap: 20px;
            }
          }
          &#case_1 {
            .case__header {
              .img-wrapper {
                img {
                  right: calc(-94px + 94 * (100vw - 320px) / (767 - 320));
                }
              }
            }
          }
          &#case_2 {
            .case__header {
              .img-wrapper {
                img {
                  height: 100%;
                }
              }
            }
          }
        }
      }
      .bacground-wrapper {
        padding: 60px 0px 70px;
      }
    }
  }
}
</style>
